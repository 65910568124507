export const handleOptionsToggle = (layoutOptions, currentToggle, toggleToBeEnabled) => {
  const enableToggle = (options, toggle) => {
    options[toggle].state = true
  }

  const isToggleArray = Array.isArray(toggleToBeEnabled)

  if (!layoutOptions[currentToggle].state) {
    if (!isToggleArray) {
      enableToggle(layoutOptions, toggleToBeEnabled)
    }
  }
  else if (isToggleArray) {
    toggleToBeEnabled.forEach(toggle => enableToggle(layoutOptions, toggle))
  }

  layoutOptions[currentToggle].state = !!layoutOptions[currentToggle].state

  if (!layoutOptions[currentToggle].state && layoutOptions.splitCodeIdAndName) {
    layoutOptions.splitCodeIdAndName.state = false
  }
}
