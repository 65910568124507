<template>
  <div class="schedules-details-body">
    <mi-tabs v-model="activeTab" class="schedules-details-body__tabs">
      <mi-tab
        v-for="(tabValue) in tabs"
        :key="tabValue.key"
        :name="tabValue.key"
        :label="tabValue.label"
        class="schedules-details-body__tab_button"
      ></mi-tab>
    </mi-tabs>
    <mi-tab-panels v-model="activeTab" keep-alive>
      <mi-tab-panel :name="tabs.SCHEDULE_SETUP.key" class="schedules-details-body__tab_panel">
        <schedules-details-setup-tab
          :schedule-details="scheduleDetails"
          :on-handle-edit="onHandleEdit"
          :fetch-schedule-details="fetchScheduleDetails"
        >
        </schedules-details-setup-tab>
      </mi-tab-panel>
      <mi-tab-panel :name="tabs.RESULTS.key" class="schedules-details-body__tab_panel">
        <collections-results-table-tab
          :key="tab2Key"
          :schedule-details="scheduleDetails"
        >
        </collections-results-table-tab>
      </mi-tab-panel>
    </mi-tab-panels>
    <div v-if="showScheduleModal">
      <schedules-form
        :handle-close-modal="handleCloseModal"
        :is-edit="true"
        :schedule-details="scheduleDetails"
        :schedule-id="scheduleId"
      >
      </schedules-form>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import MiTabPanel from '@/packages/@mi-library/MiTabPanels/MiTabPanel.vue'
  import MiTabPanels from '@/packages/@mi-library/MiTabPanels/MiTabPanels.vue'
  import { SCHEDULE_DETAILS_TABS } from '@/constants'
  import SchedulesDetailsSetupTab
    from '@/components/schedules/details/schedule-details-setup-tab/SchedulesDetailsSetupTab.vue'
  import CollectionsResultsTableTab
    from '@/components/schedules/details/collections-results-tab/CollectionsResultsTableTab.vue'
  import SchedulesForm from '@/components/schedules/drawer/SchedulesForm.vue'
  import { useRouteParams } from '@/composables/useRouteParams'

  export default {
    name: 'SchedulesDetailsBody',
    components: { CollectionsResultsTableTab, SchedulesDetailsSetupTab, MiTabPanels, MiTabPanel, SchedulesForm },
    props: {
      scheduleDetails: {
        type: Object,
        required: true
      },
      fetchScheduleDetails: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const tabs = ref(SCHEDULE_DETAILS_TABS)
      const activeTab = ref(SCHEDULE_DETAILS_TABS.SCHEDULE_SETUP.key)
      const tab2Key = ref(tabs.value.RESULTS.key)
      const showScheduleModal = ref(false)

      const { routeParams } = useRouteParams()
      const scheduleId = ref(routeParams?.value.id)

      const onHandleEdit = () => {
        showScheduleModal.value = true
      }

      const handleCloseModal = async ({ refetch }) => {
        showScheduleModal.value = false
        refetch && props.fetchScheduleDetails()
      }

      watch(activeTab, newTab => {
        if (newTab === tabs.value.RESULTS.key) {
          tab2Key.value = `${ tabs.value.RESULTS.key }-${ Date.now() }`
        }
      })

      return { activeTab, tabs, showScheduleModal, onHandleEdit, handleCloseModal, scheduleId, tab2Key }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-body ::v-deep(.q-panel) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .schedules-details-body ::v-deep(.q-tab-panel) {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .schedules-details-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .q-tab-panels {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__tabs {
      background-color: $mi-white;
      padding: 0 24px;
    }

    &__tab_button {
      font-family: "Man Europe Condensed", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      text-align: left;
      padding: 12px;
    }

    &__tab_panel {
      background-color: $mi-anthracite-50;
      padding: 0;

      .settings-side-bar {
        margin-top: 2px;
        width: 100%;
        display: flex;
      }
    }
  }
</style>
