import { onMounted, reactive, ref } from 'vue'
import { useRouteParams } from '@/composables/useRouteParams'
import { getScheduleDetails } from '@/api/rest/scheduler'
import { base64Decode } from '@/utils/convertBase64'
import formatDate from '@/utils/formatDate'

export const useSchedulesDetails = () => {
  const isSchedulesDetailsLoading = ref(false)
  const scheduleDetails = reactive(
    { name: '', scheduleDate: '', pmName: '', planningPeriod: '', encodedBusinessName: '', productModelLatestId: '' }
  )
  const { routeParams } = useRouteParams()

  const fetchScheduleDetails = async ({ loading = true } = {}) => {
    isSchedulesDetailsLoading.value = loading
    try {
      const data = await getScheduleDetails(routeParams?.value.id)

      scheduleDetails.name = data.name
      scheduleDetails.scheduleDate = formatDate(data.nextScheduledDate, { includesTime: false })
      scheduleDetails.pmName = base64Decode(data.encodedBusinessName)
      scheduleDetails.planningPeriod = data.planningPeriod.from
      scheduleDetails.encodedBusinessName = data.encodedBusinessName
      scheduleDetails.productModelLatestId = data.productModelLatestId
      scheduleDetails.department = data.department === 'SOTM' ? 'STOM' : data.department
      scheduleDetails.scheduleStatus = data.scheduleStatus
    }
    catch (err) {
      throw new Error('Failed to load the schedules details', err)
    }
    finally {
      isSchedulesDetailsLoading.value = false
    }
  }

  onMounted(() => {
    fetchScheduleDetails()
  })

  return { scheduleDetails, loading: isSchedulesDetailsLoading, fetchScheduleDetails }
}
