<template>
  <mi-btn
    icon-right="plus"
    icon-right-size="12px"
    size="md"
    :flat="isFlat"
    @click="onHandleCreateCollectionClick"
  >
    Create a collection
  </mi-btn>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { useRouteParams } from '@/composables/useRouteParams'
  import { SCHEDULES_SEARCH_ROUTE } from '@/router/routeNames'

  export default {
    name: 'CreateCollectionBtn',
    props: {
      isFlat: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      const router = useRouter()
      const { routeParams } = useRouteParams()

      const onHandleCreateCollectionClick = () => {
        router.push({ name: SCHEDULES_SEARCH_ROUTE.name, params: { id: routeParams.value.id } })
      }

      return { onHandleCreateCollectionClick }
    }
  }
</script>
