<template>
  <div class="collections-result-table-tab-wrapper">
    <mi-table
      ref="miTable"
      :columns="table.columns"
      :loading="table.loading"
      :rows="table.rows"
      :table-colspan="table.columns.length"
      class="collections-result-table"
      loading-label="Loading Collections Results. Please wait ..."
      row-key="id"
      title="Collections Results table"
      column-sort-order="da"
      hide-pagination
      sticky-header
      wrap-cells
      striped
    >
      <template #top-left>
        <div class="row no-wrap flex justify-between full-width">
          <div class="flex justify-center items-center q-mb-md">
            <mi-select
              v-if="selectedExecutionDate"
              v-model="selectedExecutionDate"
              class="executions-date-select text-family-condensed text-weight-bold"
              :display-value="selectedExecutionDate"
              :options="executionOptions"
              outlined
            >
            </mi-select>
            <mi-separator
              v-if="selectedExecutionDate"
              vertical
              inset
              class="q-ml-lg q-mr-sm"
            ></mi-separator>
            <mi-refresh-btn
              :is-loading="table.loading"
              class="collections-result-table__refresh-btn"
              @clicked-refresh-btn="refreshCollectionsResults"
            ></mi-refresh-btn>
          </div>
          <div>
            <export-settings-dropdown></export-settings-dropdown>
          </div>
        </div>
      </template>
      <template #body-cell-status="{ value }">
        <mi-td>
          <man-tag :variant="availableStatus[value]?.color" size="m">
            {{ availableStatus[value]?.title }}
          </man-tag>
        </mi-td>
      </template>
      <template #body-cell-export="{ value }">
        <mi-td>
          <export-data-btn
            :result-to-export="value"
            :schedule-details="scheduleDetails"
            :selected-export-settings="selectedExportSettings"
          >
          </export-data-btn>
        </mi-td>
      </template>
      <template v-if="!table.loading" #no-data>
        <div class="no-data-message">
          The collection results will be found here.
        </div>
      </template>
    </mi-table>
  </div>
</template>

<script>
  import { onMounted, provide, ref, computed, watch } from 'vue'
  import { useTable } from '@/composables/useTable'
  import {
    COLLECTIONS_RESULT_STATUS,
    COLLECTIONS_RESULTS_TABLE_COLUMNS,
    EXPORT_SETTINGS_DEFAULT,
    EXPORT_SETTINGS_PROVIDER
  } from '@/constants'

  import { useRouteParams } from '@/composables/useRouteParams'
  import { getScheduleCollectionsResultsList, getScheduleExecutionList } from '@/api'

  import { formatMonthYear } from '@/utils/formatDate'
  import ExportSettingsDropdown from './ExportSettingsDropdown.vue'
  import ExportDataBtn from './ExportDataBtn.vue'

  export default {
    name: 'CollectionsResultsTableTab',
    components: { ExportDataBtn, ExportSettingsDropdown },
    props: {
      scheduleDetails: {
        type: Object,
        required: true
      }
    },
    setup() {
      const availableStatus = ref(COLLECTIONS_RESULT_STATUS)
      const { table, callbacks: { fetchTableData } } = useTable(COLLECTIONS_RESULTS_TABLE_COLUMNS)
      const { routeParams } = useRouteParams()
      const selectedExecutionDate = ref(null)
      const executionsDates = ref(new Map())

      const selectedExportSettings = ref(EXPORT_SETTINGS_DEFAULT)
      provide(EXPORT_SETTINGS_PROVIDER, selectedExportSettings)

      const setExecutionList = executionList => {
        const formattedDates = formatMonthYear(executionList)

        formattedDates.forEach(({ month, year, formattedDate }) => {
          executionsDates.value.set(formattedDate, { month, year, formattedDate })
        })

        const mostRecentExecution = executionsDates.value.values().next().value
        selectedExecutionDate.value = mostRecentExecution.formattedDate
      }

      const executionOptions = computed(() => [...executionsDates.value.values()].map(date => date.formattedDate))

      const fetchScheduleCollectionsResultsList = async () => {
        try {
          table.loading = true

          const scheduleId = routeParams?.value.id
          let executionsDatesAvailable = Array.from(executionsDates.value.values())

          if (executionsDatesAvailable.length === 0) {
            const scheduleExecutionList = await getScheduleExecutionList(scheduleId)
            if (scheduleExecutionList.length > 0) {
              setExecutionList(scheduleExecutionList)
              executionsDatesAvailable = Array.from(executionsDates.value.values())
            }
          }

          const selectedExecution = executionsDates.value.get(selectedExecutionDate.value)

          if (!selectedExecution) {
            console.warn('Selected execution date is not available')
            return
          }

          const { month, year } = selectedExecution
          const params = { month, year }

          const scheduleResults = () => getScheduleCollectionsResultsList(scheduleId, params)

          await fetchTableData(scheduleResults)
        }
        catch (err) {
          console.error('Failed to fetch schedule collections results list:', err)
        }
        finally {
          table.loading = false
        }
      }

      watch(() => selectedExecutionDate.value, async (_, previousValue) => {
        if (previousValue) await fetchScheduleCollectionsResultsList()
      })

      const refreshCollectionsResults = async () => {
        await fetchScheduleCollectionsResultsList()
      }

      onMounted(async () => {
        await fetchScheduleCollectionsResultsList()
      })

      return {
        table,
        refreshCollectionsResults,
        availableStatus,
        selectedExportSettings,
        selectedExecutionDate,
        executionOptions
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .collections-result-table-tab-wrapper {
    width: 100%;
    padding: 16px;
    background: $mi-white;
    border: solid;
    border-width: 16px 16px 0;
    border-color: $mi-anthracite-50;
  }

  .mi-table-wrapper .q-table__bottom--nodata {
    height: 100%;
    width: 100%;
    align-items: center;

    .no-data-message {
      font-size: 14px;
      font-family: $mi-typography-font-family-condensed;
      font-weight: 700;
      color: $mi-anthracite-800;
    }
  }

  ::v-deep(.q-table__bottom--nodata) {
    border-width: 0 1px 1px !important;
    border-style: dashed !important;
    border-color: $mi-anthracite-100 !important;
    top: 80px !important;
  }

  .collections-result-table {
    height: 100%;
  }

  ::v-deep(.q-table-control) {
    width: 100%;
  }

  ::v-deep(.q-table__top) {
    padding: 0;
  }

  ::v-deep(.q-field) {
    border: 1px solid;
    color: $mi-anthracite-800;
  }

  ::v-deep(.q-field__control) {
    padding: 0 12px !important;

    .q-field__native,
    .q-field__marginal {
      color: $mi-anthracite-800;
    }
  }
</style>
