import { onMounted, ref } from 'vue'

export const useRecentSearch = () => {
  const recentSearchItems = ref([])

  // removes selected item from recent search items
  const removeRecentSearchItem = (recentSearchItem = '') => {
    recentSearchItems.value = recentSearchItems.value.filter(searchItem => searchItem !== recentSearchItem)
    localStorage.setItem(
      process.env.VUE_APP_STORAGE_KEY_SCHEDULE_RECENT_SEARCH,
      recentSearchItems.value
    )
  }

  const updateRecentSearchItems = (searchStr = '') => {
    const previousRecentSearchItems = recentSearchItems.value.filter(searchItem => searchItem !== searchStr)
    if (previousRecentSearchItems.length === 5) {
      previousRecentSearchItems.pop()
    }

    recentSearchItems.value = [searchStr, ...previousRecentSearchItems].slice(0, 5)
    localStorage.setItem(process.env.VUE_APP_STORAGE_KEY_SCHEDULE_RECENT_SEARCH, recentSearchItems.value)
  }

  onMounted(() => {
    const storedItemsString = localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_SCHEDULE_RECENT_SEARCH)
    if (storedItemsString) {
      recentSearchItems.value = [...storedItemsString.split(',')]
    }
  })

  return {
    recentSearchItems,
    removeRecentSearchItem,
    updateRecentSearchItems
  }
}
