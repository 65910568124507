<template>
  <div class="schedule-table-wrapper">
    <div v-if="showBanner" class="q-mb-sm">
      <man-banner
        header="Jobs are scheduled to start today at 4PM (GMT+2)"
        content="Make sure any updates to the collections are done before we send it to CoRE."
        variant="info"
        dismissable="false"
      ></man-banner>
    </div>
    <mi-table
      ref="miTable"
      :columns="table.columns"
      :loading="table.loading"
      :rows="table.rows"
      :table-colspan="table.columns.length"
      :custom-header="true"
      :class="`schedule-table ${ !table.rows.length ? 'schedule-table-empty': '' }`"
      loading-label="Loading Collections. Please wait ..."
      hide-no-data
      row-key="id"
      title="Schedule table"
      :on-change-sort-table-direction="tableCallbacks.onChangeSortTableDirection"
      :on-change-sort-table-column-visibility="tableCallbacks.onChangeSortTableColumnVisibility"
      column-sort-order="da"
      hide-pagination
      sticky-header
      striped
      virtual-scroll
      wrap-cells
    >
      <template #top-left>
        <div class="row no-wrap">
          <div class="buttons-wrap flex justify-between full-width">
            <div>
              <mi-refresh-btn
                :is-loading="table.loading"
                class="schedule-table__refresh-btn"
                @clicked-refresh-btn="refreshCollections"
              ></mi-refresh-btn>
            </div>
            <div class="btn-wrapper">
              <create-collection-btn></create-collection-btn>
            </div>
          </div>
        </div>
      </template>
      <template #body-cell-actions="{ row }">
        <mi-td class="autofix-and-edit">
          <mi-btn color="transparent">
            <q-icon class="text-dark" size="small">
              <img
                src="@/assets/images/dots_vertical.svg"
                alt="Edit collection"
              />
            </q-icon>
            <mi-menu>
              <q-list>
                <q-item
                  v-close-popup
                  clickable
                  @click="handleOpenModal(row, collectionOptions.EDIT)"
                >
                  <q-item-section>
                    <q-item-label>
                      Edit collection
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  v-close-popup
                  clickable
                  @click="handleOpenModal(row, collectionOptions.SEND_TO_SEARCH)"
                >
                  <q-item-section>
                    <q-item-label>
                      Send to Search
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </mi-menu>
          </mi-btn>
        </mi-td>
      </template>
    </mi-table>
    <schedule-setup-table-empty-state v-if="!table.rows.length && !table.loading"></schedule-setup-table-empty-state>
    <div class="pagination q-lg flex flex-center">
      <mi-pagination
        v-if="!!table.rows.length"
        :model-value="pagination.currentPage"
        :max="pagination.totalPages"
        :max-pages="pagination.maxNavPage"
        size="14px"
        boundary-links
        direction-links
        boundary-numbers
        ellipses
        @update:model-value="handlePaginationChange"
      ></mi-pagination>
    </div>
  </div>
  <autofix-confirmation-modal
    :is-modal-open="showAutoFixModal"
    :handle-close-modal="handleCloseModal"
    :schedule-id="scheduleId"
    :collection-id="collectionIdentifier"
    :execute-auto-fix="executeAutoFix"
  ></autofix-confirmation-modal>
</template>

<script>
  import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue'
  import ScheduleSetupTableEmptyState
    from '@/components/schedules/details/schedule-details-setup-tab/table/ScheduleSetupTableEmptyState.vue'
  import CreateCollectionBtn
    from '@/components/schedules/details/schedule-details-setup-tab/create-collection-btn/CreateCollectionBtn.vue'
  import { useTable } from '@/composables/useTable'
  import { usePagination } from '@/composables/usePagination'
  import { autoFixCollection, getScheduleCollectionsList, saveToSummary } from '@/api'
  import { useRouteParams } from '@/composables/useRouteParams'
  import {
    SCHEDULE_COLLECTIONS_TABLE_COLUMNS,
    SCHEDULE_COLLECTION_OPTIONS, PM_TYPE, SEARCH_RESULT_TYPES
  } from '@/constants'
  import { onBeforeRouteUpdate, useRouter } from 'vue-router'
  import { SCHEDULES_SEARCH_ROUTE, VARIANT_MANAGEMENT_ROUTE } from '@/router/routeNames'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import AutofixConfirmationModal
    from '@/components/schedules/details/schedule-details-setup-tab/confirmation-modal/AutofixConfirmationModal.vue'
  import notify from '@/utils/notify'
  import * as notificationTypes from '@/plugins/quasar/notifications/notificationTypes'
  import { getRemainingDays, parseDateString } from '@/utils/formatDate'
  import { useStore } from 'vuex'

  export default {
    name: 'ScheduleSetupTable',
    components: {
      MiBtn,
      ScheduleSetupTableEmptyState,
      CreateCollectionBtn,
      AutofixConfirmationModal
    },
    props: {
      schedule: {
        type: Object,
        required: true
      },
      fetchScheduleDetails: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const { table, callbacks: tableCallbacks } = useTable(SCHEDULE_COLLECTIONS_TABLE_COLUMNS)
      const { pagination, handleChange: handlePaginationChange } = usePagination({ pageSize: 15 })
      const { routeParams } = useRouteParams()
      const scheduleId = ref(routeParams?.value.id)
      const showAutoFixModal = ref(false)
      const collectionIdentifier = ref('')
      const router = useRouter()
      const collectionOptions = ref(SCHEDULE_COLLECTION_OPTIONS)
      const optionsRedirectPath = ref(collectionOptions.value.EDIT)
      const { proxy } = getCurrentInstance()
      const store = useStore()

      const buildUrlSearchParams = () => {
        const params = new URLSearchParams()
        params.append('page', pagination.currentPage - 1)
        params.append('pageSize', pagination.pageSize)
        params.append('sortBy', table.sort?.sortBy || 'lastEdited')
        params.append('sortDirection', table.sort?.sortDirection || 'DESC')
        return params.toString()
      }

      const fetchScheduleCollectionsList = async () => {
        table.loading = true
        const params = buildUrlSearchParams()
        const fetchScheduleCollectionsListPromise = () => getScheduleCollectionsList(
          scheduleId.value, params
        )
        const data = await tableCallbacks.fetchTableData(fetchScheduleCollectionsListPromise)
        pagination.totalPages = data?.totalPages || 1
        pagination.totalElements = data?.totalElements
        table.loading = false
      }

      const refreshCollections = async () => {
        await props.fetchScheduleDetails({ loading: false })
        await fetchScheduleCollectionsList()
      }

      const emptySummaryElements = () => {
        store.commit('search/REPLACE_SELECTED_ELEMENTS', {
          elementsType: SEARCH_RESULT_TYPES.OPTIONS,
          subElementsType: SEARCH_RESULT_TYPES.CHOICES,
          elements: []
        })
        store.commit('search/REPLACE_SELECTED_ELEMENTS', {
          elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
          subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
          elements: []
        })
      }

      const setToSearch = (key, value) => {
        proxy.$q.localStorage.remove(key)
        proxy.$q.localStorage.set(key, value)
      }

      const sendToSearch = async () => {
        try {
          await emptySummaryElements()

          await saveToSummary(scheduleId.value, collectionIdentifier.value)

          const planningPeriod = { to: props.schedule.planningPeriod, from: props.schedule.planningPeriod }
          setToSearch(process.env.VUE_APP_STORAGE_KEY_PLANNING_PERIOD, planningPeriod)

          const productModel = {
            id: props.schedule.productModelLatestId,
            encodedBusinessName: props.schedule.encodedBusinessName,
            productModelType: PM_TYPE.PRODUCT_MODEL
          }
          setToSearch(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL, productModel)

          store.commit('search/SET_ELEMENTS_LOADING_STATE', true)

          router.push({
            name: VARIANT_MANAGEMENT_ROUTE.name
          })
        }
        catch (error) {
          console.error(error)
        }
      }

      const executeAutoFix = async ({ collectionId, redirect = false }) => {
        try {
          const updatedCollection = await autoFixCollection({ scheduleId: scheduleId.value, collectionId })

          tableCallbacks.updateTableRow('id', collectionId, updatedCollection)

          if (redirect && optionsRedirectPath.value === collectionOptions.value.EDIT) {
            router.push({
              name: SCHEDULES_SEARCH_ROUTE.name,
              params: {
                id: scheduleId.value,
                edit: redirect,
                collectionId: collectionIdentifier.value
              }
            })
          }

          if (redirect && optionsRedirectPath.value === collectionOptions.value.SEND_TO_SEARCH) {
            sendToSearch()
          }

          notify({
            content: 'Collection auto fixed.',
            type: notificationTypes.BASIC_INFO,
            position: 'bottom'
          })
        }
        catch (error) {
          notify({
            title: 'AUTO FIX FAILED.',
            content: 'Elements are not updated to collection. Retry it later.',
            type: 'negative'
          })
        }
      }

      const showBanner = computed(() => {
        const isToday = getRemainingDays(parseDateString(props.schedule.scheduleDate)) === 0

        const now = new Date()
        const options = { timeZone: 'Europe/Berlin', hour: '2-digit', minute: '2-digit' }
        const localTimeString = now.toLocaleString('en-DE', options)
        const [currentLocalHour] = localTimeString.split(':').map(num => parseInt(num, 10))

        return isToday && currentLocalHour < 16
      })

      watch(() => [pagination.currentPage, table.sort], () => {
        fetchScheduleCollectionsList()
      })

      onMounted(async () => {
        await fetchScheduleCollectionsList()
      })

      onBeforeRouteUpdate(async (_, from) => {
        if (from.name === SCHEDULES_SEARCH_ROUTE.name) await refreshCollections()
      })

      const handleOpenModal = async (collection, redirectTo) => {
        collectionIdentifier.value = collection.id
        optionsRedirectPath.value = redirectTo

        if (collection.productModelIdLastEdited !== props.schedule.productModelLatestId) {
          showAutoFixModal.value = true
        }
        else {
          optionsRedirectPath.value === collectionOptions.value.EDIT
            ? router.push({
              name: SCHEDULES_SEARCH_ROUTE.name,
              params: {
                id: scheduleId.value,
                edit: true,
                collectionId: collectionIdentifier.value
              }
            }) : sendToSearch()
        }
      }

      const handleCloseModal = () => {
        showAutoFixModal.value = false
      }

      return {
        pagination,
        table,
        tableCallbacks,
        handlePaginationChange,
        refreshCollections,
        fetchScheduleCollectionsList,
        buildUrlSearchParams,
        scheduleId,
        handleOpenModal,
        showAutoFixModal,
        handleCloseModal,
        collectionIdentifier,
        executeAutoFix,
        showBanner,
        collectionOptions
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedule-table-wrapper {
    width: 80%;
    padding: 16px 16px 0;
    background: $mi-white;
    border: solid;
    border-width: 16px 16px 0;
    border-color: $mi-anthracite-50;
  }

  .schedule-table {
    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }

    .table-row:hover {
      // stylelint-disable declaration-no-important
      background: $mi-anthracite-50 !important;
    }
  }

  .schedule-table-empty {
    height: 100%;

    ::v-deep(.q-table th) {
      width: 200px;
    }
  }

  .schedule-table .schedule-table__status-cell {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  ::v-deep(.q-table-control) {
    width: 100%;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .pagination {
    padding: 8px 0;
  }

  ::v-deep(.q-table__top) {
    padding: 0;
  }

  .q-btn {
    height: 32px;
    margin: 6px 0;
    justify-content: center;
  }

  .autofix-and-edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100% !important;
    gap: 16px;
  }

</style>
