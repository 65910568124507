<template>
  <div class="schedules-details-settings__card">
    <div v-for="setting in settings" :key="setting.value">
      <div class="schedules-details-settings__item">
        <div>
          <p class="label">
            <strong class="text-family-condensed"> {{ setting.label }} </strong>
          </p>
          <p class="info"> {{ setting.value }} </p>
        </div>
        <div
          v-if="setting.status"
          class="schedules-details-settings__status"
          :style="scheduleStatus.style"
        >
          {{ scheduleStatus.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: 'SchedulesDetailsSettingsCard',
    props: {
      settings: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const scheduleStatus = computed(() => {
        const settings = props.settings[0]

        if (!settings.status) return {}

        const IN_PROGRESS_STATUS = {
          style: {
            backgroundColor: '#bfddf1',
            color: '#244a82'
          },
          text: 'Jobs in progress'
        }

        const COMPLETED_STATUS = {
          style: {
            backgroundColor: '#dfe9bc',
            color: '#2d6300'
          },
          text: 'Finished'
        }

        return settings.status === 'IN_PROGRESS' ? IN_PROGRESS_STATUS : COMPLETED_STATUS
      })

      return {
        scheduleStatus
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-settings {
    &__card {
      background-color: #eaedf366;
      margin: 18px 0;
      padding: 8px;
      border-radius: 4px;

      .label {
        margin-bottom: 4px;
        color: $mi-anthracite-800;
        font-size: 12px;
        line-height: 15.6px;
      }

      .info {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 8px;
      }
    }

    &__status {
      padding: 5px 8px;
      border-radius: 50px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  .schedules-details-settings__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
</style>
