<template>
  <schedules-details-container></schedules-details-container>
  <router-view></router-view>
</template>

<script>
  import SchedulesDetailsContainer from '@/components/schedules/details/SchedulesDetailsContainer.vue'

  export default {
    name: 'SchedulesDetails',
    components: { SchedulesDetailsContainer }
  }
</script>
