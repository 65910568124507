<template>
  <div class="form-label__wrapper">
    <div class="form-label__wrapper-icon">
      <mi-icon :name="icon" size="16px"></mi-icon>
    </div>
    <div class="form-label__wrapper-text">
      <h4 class="form-label__header">
        {{ header }}
      </h4>
      <span class="form-label__text">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SchedulesDrawerLabel',
    props: {
      header: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: false,
        default: 'none'
      }
    }
  }
</script>

<style scoped lang="scss">
  @media (max-width: $mi-responsive-medium-width) {
    .form-label__wrapper {
      // stylelint-disable declaration-no-important
      width: 100% !important;
    }
  }

  .form-label {
    &__wrapper {
      width: 35%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
    }

    &__wrapper-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__header {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      color: $mi-anthracite-800;
    }

    &__input {
      width: 50%;
    }

    &__wrapper-icon {
      padding: 12px;
      border: 1px solid $mi-anthracite-100;
      display: flex;
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $mi-anthracite-800;
    }
  }
</style>
