<template>
  <div class="schedules-details-settings">
    <div class="schedules-details-settings__header flex">
      <h6>Settings</h6>
      <mi-btn v-if="!isScheduleDateExpired" flat @click="onHandleEdit">Edit</mi-btn>
    </div>
    <schedules-details-settings-card
      :settings="[{ label: 'Product Model', value: scheduleDetails.pmName },
                  { label: 'Planning Period', value: scheduleDetails.planningPeriod } ]"
    >
    </schedules-details-settings-card>
    <schedules-details-settings-card
      :settings="[{
        label: 'Schedule Date',
        value: scheduleDetails.scheduleDate,
        status: scheduleDetails.scheduleStatus
      }]"
    >
    </schedules-details-settings-card>
    <div class="flex">
      <mi-btn
        v-if="isScheduleDateExpired"
        class="full-width"
        color="accent"
        size="md"
        @click="onHandleEdit"
      >
        Set next schedule
      </mi-btn>
    </div>
  </div>
</template>

<script>

  import SchedulesDetailsSettingsCard
    from '@/components/schedules/details/schedule-details-setup-tab/settings/SchedulesDetailsSettingsCard.vue'
  import { getRemainingDays, parseDateString } from '@/utils/formatDate'
  import { computed } from 'vue'

  export default {
    name: 'SchedulesDetailsSettings',
    components: { SchedulesDetailsSettingsCard },
    props: {
      scheduleDetails: {
        type: Object,
        required: true
      },
      onHandleEdit: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const isScheduleDateExpired = computed(() => {
        if (!props.scheduleDetails.scheduleDate) return false
        return getRemainingDays(parseDateString(props.scheduleDetails.scheduleDate)) < 0
      })

      return { isScheduleDateExpired }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-settings {
    min-height: max-content;
    width: 100%;
    padding: 16px;
    background-color: $mi-white;

    &__header {
      align-items: center;

      h6 {
        margin: 0;
      }

      .mi-btn {
        margin-left: auto;
      }
    }
  }
</style>
