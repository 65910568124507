<template>
  <mi-btn
    v-if="resultToExport.status === successResultStatus"
    :loading="isExportDataBtnLoading"
    @click="exportData"
  >
    Export Data
  </mi-btn>
</template>

<script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { exportVariantTreeTable } from '@/api'
  import { ref } from 'vue'
  import * as notificationTypes from '@/plugins/quasar/notifications/notificationTypes'
  import notify from '@/utils/notify'
  import { exportFile } from 'quasar'
  import { buildExcelName } from '@/components/saved-tables/utils'
  import { COLLECTIONS_RESULT_STATUS } from '@/constants'

  export default {
    name: 'ExportDataBtn',
    components: { MiBtn },
    props: {
      resultToExport: {
        type: Object,
        required: true
      },
      scheduleDetails: {
        type: Object,
        required: true
      },
      selectedExportSettings: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const isExportDataBtnLoading = ref(false)
      const successResultStatus = ref(COLLECTIONS_RESULT_STATUS.SUCCESS.key)

      const exportData = async () => {
        try {
          isExportDataBtnLoading.value = true

          const splitCodeIdAndName = props.selectedExportSettings.splitCodeIdAndName.state

          const excelFile = await exportVariantTreeTable({
            showCodeId: props.selectedExportSettings.showCodeId.state,
            showName: props.selectedExportSettings.showName.state,
            splitCodeIdAndName,
            correlationId: props.resultToExport.correlationId
          })

          const vmExcelName = buildExcelName(
            props.resultToExport.collectionName,
            props.scheduleDetails.planningPeriod,
            props.scheduleDetails.pmName,
            splitCodeIdAndName
          )

          exportFile(vmExcelName, new Blob([excelFile]))

          notify({
            content: 'Table exported',
            type: notificationTypes.BASIC_INFO,
            position: 'bottom'
          })
        }
        finally {
          isExportDataBtnLoading.value = false
        }
      }

      return {
        exportData,
        isExportDataBtnLoading,
        successResultStatus
      }
    }
  }
</script>

<style lang="scss" scoped>
  .q-btn {
    justify-content: center;
    font-size: 14px;
    height: 32px;
  }
</style>
