<template>
  <mi-btn-dropdown flat small label="Export Settings" class="q-ml-md" @click="openExportSettings">
    <div class="dropdown-wrapper">
      <span class="filter-text q-mb-md self-start"> Exporting Settings </span>
      <mi-list>
        <mi-list-item class="filter-wrapper">
          <mi-list-item-section class="filter-switch">
            <div class="switch-wrapper">
              <mi-switch
                v-model="currentExportSettings.showName.state"
                @click="handleLayoutOptionsToggle(
                  currentExportSettings.showName.key,
                  currentExportSettings.showCodeId.key)"
              >
              </mi-switch>
              <span class="text-body2 text-weight-regular"> {{ currentExportSettings.showName.title }} </span>
            </div>
            <div class="switch-wrapper">
              <mi-switch
                v-model="currentExportSettings.showCodeId.state"
                @click="handleLayoutOptionsToggle(
                  currentExportSettings.showCodeId.key,
                  currentExportSettings.showName.key)"
              >
              </mi-switch>
              <span class="text-body2 text-weight-regular"> {{ currentExportSettings.showCodeId.title }} </span>
            </div>
            <div class="switch-wrapper">
              <mi-switch
                v-model="currentExportSettings.splitCodeIdAndName.state"
                @click="handleLayoutOptionsToggle(
                  currentExportSettings.splitCodeIdAndName.key,
                  [currentExportSettings.showName.key, currentExportSettings.showCodeId.key])"
              >
              </mi-switch>
              <span class="text-body2 text-weight-regular">
                {{ currentExportSettings.splitCodeIdAndName.title }}
              </span>
            </div>
          </mi-list-item-section>
        </mi-list-item>
      </mi-list>
      <mi-btn
        v-close-popup
        size="md"
        class="q-ml-sm q-mt-lg export-button"
        color="accent"
        icon-right-size="14px"
        @click="applySettings"
      >
        Apply
      </mi-btn>
    </div>
  </mi-btn-dropdown>
</template>

<script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { inject, ref } from 'vue'
  import cloneDeep from 'lodash.clonedeep'
  import { EXPORT_SETTINGS_PROVIDER, EXPORT_SETTINGS_DEFAULT } from '@/constants'
  import { handleOptionsToggle } from '@/utils/optionsToggle'

  export default {
    name: 'ExportSettingsDropdown',
    components: { MiBtn },
    setup() {
      const selectedExportSettings = inject(EXPORT_SETTINGS_PROVIDER)
      const currentExportSettings = ref(EXPORT_SETTINGS_DEFAULT)

      const applySettings = () => {
        selectedExportSettings.value = cloneDeep(currentExportSettings.value)
      }

      const openExportSettings = () => {
        currentExportSettings.value = cloneDeep(selectedExportSettings.value)
      }

      const handleLayoutOptionsToggle = (currentToggle, toggleToBeEnabled) => {
        handleOptionsToggle(currentExportSettings.value, currentToggle, toggleToBeEnabled)
      }

      return {
        currentExportSettings,
        applySettings,
        handleLayoutOptionsToggle,
        openExportSettings
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .dropdown-wrapper {
    padding: 8px;
    padding-right: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .dropdown-wrapper-gap {
    gap: 16px;
  }

  .filter-switch {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }

  .switch-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 0;
    height: 24px;
  }

  .filter-text {
    font-style: normal;
    font-weight: 700;
    padding-left: 8px;
    font-family: $mi-typography-font-family-condensed;
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    color: $mi-anthracite-800;
  }

  .filter-text:first-child {
    padding-top: 10px;
  }

  .filter-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }

  .export-button {
    width: 248px;
    height: 32px;
  }

  .only-incomplete .q-toggle__inner--falsy .q-toggle__track {
    background-color: #5b6f85 !important;
  }
</style>
