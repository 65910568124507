import { base64EncodeUnicode } from '@/utils/base64'

export const encodeSearchTerm = (searchStr = '') => searchStr
  .trim()
  .split(';')
  .map(item => base64EncodeUnicode(item.trim()))
  .filter(item => item.length)
  .join(',') || ''

export * from '@/components/schedules/utils/composables/useElements'
export * from '@/components/schedules/utils/composables/useSchedulesDetails'
export * from '@/components/schedules/utils/composables/useRecentSearch'
