<template>
  <div class="schedules-details-setup-tab flex">
    <schedule-setup-table
      :schedule="scheduleDetails"
      :fetch-schedule-details="fetchScheduleDetails"
    ></schedule-setup-table>
    <div class="settings-side-bar">
      <schedules-details-settings
        :schedule-details="scheduleDetails"
        :on-handle-edit="onHandleEdit"
      ></schedules-details-settings>
    </div>
  </div>
</template>

<script>
  import SchedulesDetailsSettings
    from '@/components/schedules/details/schedule-details-setup-tab/settings/SchedulesDetailsSettings.vue'
  import ScheduleSetupTable
    from '@/components/schedules/details/schedule-details-setup-tab/table/ScheduleSetupTable.vue'

  export default {
    name: 'SchedulesDetailsSetupTab',
    components: { ScheduleSetupTable, SchedulesDetailsSettings },
    props: {
      scheduleDetails: {
        type: Object,
        required: true
      },
      fetchScheduleDetails: {
        type: Function,
        required: false,
        default: () => {}
      },
      onHandleEdit: {
        type: Function,
        required: false,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-setup-tab {
    min-height: 100%;
    flex: 1;

    .settings-side-bar {
      margin-top: 2px;
      width: 20%;
      display: flex;
    }
  }
</style>
