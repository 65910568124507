<template>
  <div class="scheduler-drawer-wrapper">
    <mi-drawer
      v-model="isDrawerShown"
      overlay
      bordered
      no-swipe-close
      no-swipe-open
      persistent
      side="left"
      class="mi-drawer"
    >
      <div>
        <div class="drawer-wrapper-header">
          <h3 class="drawer-header">Schedule</h3>
          <mi-btn
            icon-size="16px"
            icon="close"
            fab
            flat
            @click="handleCloseModal"
          ></mi-btn>
        </div>
        <mi-separator class="separator"></mi-separator>
      </div>

      <div class="mi-drawer-content">
        <div class="form-wrapper">
          <mi-form
            class="schedules-form"
            @submit.prevent="handleFormSubmit"
          >
            <!-- Form fields go here -->
            <div>
              <div class="form-input-wrapper flex">
                <schedules-drawer-label
                  header="Folder Setup"
                  label="Let anyone in the department locate easily in which folder the schedule is."
                  icon="folder"
                ></schedules-drawer-label>
                <mi-text-field
                  v-model="form.name"
                  label="Folder Name"
                  :rules="folderNameRules"
                  placeholder="Enter a folder name"
                  outlined
                  required
                  autofocus
                ></mi-text-field>
              </div>
              <div class="form-input-wrapper flex">
                <schedules-drawer-label
                  header="Job Setup"
                  label="Select the product model and planning period for this project."
                  icon="settings"
                ></schedules-drawer-label>
                <div class="form-pp-wrapper">
                  <mi-select
                    v-model="form.productModel"
                    :display-value="form.productModel?.businessName || 'Select Product Model'"
                    :options="productModels"
                    :rules="productModelValidationRules"
                    class="q-mb-sm select-pm-dropdown"
                    label="Product Model"
                    option-label="businessName"
                    options-selected-class="text-accent"
                    outlined
                    hide-bottom-space
                  ></mi-select>
                  <mi-text-field
                    v-model="form.selectedPP"
                    :rules="planningPeriodRules"
                    label="Planning Period"
                    mask="######"
                    placeholder="YYYYMM"
                    prefix="PP"
                    outlined
                  ></mi-text-field>
                </div>
              </div>
              <div class="form-input-wrapper flex form-input-pp">
                <schedules-drawer-label
                  header="Schedule Date"
                  label="Select a date for job calculations to run (beginning at 4PM)."
                  icon="calendar"
                ></schedules-drawer-label>
                <mi-calendar
                  v-model="form.nextScheduledDate"
                  :rules="dateRules"
                ></mi-calendar>
              </div>
            </div>
            <div class="confirm-btns-wrapper">
              <mi-btn flat @click="handleCloseModal">
                Cancel
              </mi-btn>
              <mi-btn
                color="accent"
                type="submit"
                :loading="!!loading"
              >
                {{ isEdit ? 'Update schedule' : 'Create schedule' }}
              </mi-btn>
            </div>
          </mi-form>
        </div>
      </div>
    </mi-drawer>
  </div>
  <mi-overlay class="overlay"></mi-overlay>
</template>

<script>
  import MiDrawer from '@/packages/@mi-library/MiDrawer/MiDrawer.vue'
  import MiForm from '@/packages/@mi-library/MiForm/MiForm.vue'
  import MiCalendar from '@/packages/@mi-library/MiCalendar/MiCalendar.vue'
  import { maxLength, regex, required } from '@/utils/validators'
  import { validDate, maxDate, minDate } from '@/utils/validators/date'
  import { getLastDayOfYear } from '@/utils/formatDate'
  import { createSchedule, updateSchedule } from '@/api/rest/scheduler'
  import { getProductModels } from '@/api/rest/product-model'
  import { PM_TYPE } from '@/constants'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import SchedulesDrawerLabel from './SchedulesDrawerLabel.vue'

  export default {
    name: 'SchedulesForm',
    components: {
      MiForm,
      MiDrawer,
      SchedulesDrawerLabel,
      MiCalendar
    },
    props: {
      handleCloseModal: {
        type: Function,
        required: true,
        default: () => {}
      },
      departmentName: {
        type: String,
        required: false,
        default: ''
      },
      isEdit: {
        type: Boolean,
        required: false,
        default: false
      },
      scheduleDetails: {
        type: Object,
        required: false,
        default: () => {}
      },
      scheduleId: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: () => ({
      isDrawerShown: true,
      loading: false,
      productModels: [],
      isDisabled: true,
      form: {
        name: '',
        productModel: {},
        department: '',
        nextScheduledDate: '',
        encodedBusinessName: '',
        selectedPP: ''
      },
      dateRules: [
        required,
        validDate('Invalid date'),
        minDate(Date.now(), 'Not allowed to schedule current day'),
        maxDate(getLastDayOfYear(), 'Not allowed to schedule for future years')
      ],
      planningPeriodRules: [
        required,
        regex(
          /^(20[0-9][0-9]|2100)(0[1-9]|1[0-9]|2[0-9]|3[0-6])$/,
          'Enter a valid planning period'
        )
      ],
      folderNameRules: [
        required,
        maxLength(80, 'Max length of 80 characters reached')
      ],
      productModelValidationRules: [required]
    }),
    computed: {
      isFormValid() {
        return (
          this.form.name
          && this.form.productModel
          && this.form.productModel.encodedBusinessName
          && this.form.selectedPP
          && this.form.nextScheduledDate
          && this.dateRules.every(rule => rule(this.form.nextScheduledDate) === true)
          && this.planningPeriodRules.every(rule => rule(this.form.selectedPP) === true)
        )
      }
    },
    async created() {
      this.prefillForm()
      const pms = await getProductModels()
      this.productModels = pms.filter(pm => pm.productModelType === PM_TYPE.PRODUCT_MODEL) || []
    },
    methods: {
      async handleFormSubmit() {
        this.loading = true

        const [day, month, year] = this.form.nextScheduledDate.split('/')
        const date = new Date(year, month - 1, day)
        date.setHours(12, 0, 0, 0)

        const payload = {
          ...this.form,
          productModel: this.productModels
            .find(pm => pm.encodedBusinessName === this.form.productModel.encodedBusinessName),
          encodedBusinessName: this.form.productModel.encodedBusinessName,
          planningPeriod: {
            from: `PP${ this.form.selectedPP }`,
            to: `PP${ this.form.selectedPP }`
          },
          nextScheduledDate: date.toISOString(),
          department: this.departmentName || this.scheduleDetails.department
        }

        const scheduleId = !this.isEdit
          ? await createSchedule(payload)
          : await updateSchedule({ ...payload, scheduleId: this.scheduleId })

        this.loading = false
        this.handleCloseModal({ refetch: true, created: true, id: scheduleId })
      },

      prefillForm() {
        if (this.isEdit) {
          this.form.name = this.scheduleDetails.name
          this.form.selectedPP = removePrefixFromPlanningPeriod(this.scheduleDetails.planningPeriod)
          this.form.encodedBusinessName = this.scheduleDetails.encodedBusinessName
          this.form.productModel = {
            businessName: atob(this.scheduleDetails.encodedBusinessName),
            encodedBusinessName: this.scheduleDetails.encodedBusinessName
          }
          this.form.nextScheduledDate = this.scheduleDetails.scheduleDate
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.q-drawer) {
    // stylelint-disable declaration-no-important
    top: 0 !important;
    width: 990px !important;

    .q-drawer__content {
      display: flex;
      flex-direction: column;
    }

    .form-label {
      margin-bottom: 16px;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    ::v-deep(.q-drawer) {
      width: 100vw !important;
    }

    .form-input-wrapper {
      flex-direction: column;
      gap: 23px !important;
      align-items: flex-start !important;
    }
  }

  .scheduler-drawer-wrapper {
    min-height: inherit;
  }

  .drawer-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    color: $mi-anthracite-800;
  }

  .form-wrapper {
    padding: 32px 24px;
    height: 100%;
  }

  .mi-drawer-content {
    flex-grow: 1;
  }

  .schedules-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  .form-pp-wrapper {
    display: flex;
    gap: 20px;
    flex-grow: 1;
  }

  .form-input-wrapper {
    border-bottom: 1px solid $mi-anthracite-50;
    margin-bottom: 32px;
    gap: 47px;
    justify-content: flex-start;
    min-height: 134px;
    align-items: center;
  }

  .drawer-wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    height: 63px;
  }

  .select-pm-dropdown {
    min-width: 50%;
    height: 40px;
  }

  .mi-field.q-field .q-field__control {
    height: auto;
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  .form-input-pp > .mi-field.q-field.q-field--labeled {
    width: 166px;
  }

  .mi-field.q-field.q-field--labeled {
    width: 60%;
  }

  .q-field__control-container > textarea {
    height: 50px;
    line-height: 40px;
  }

  .confirm-btns-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .separator {
    background-color: $mi-anthracite-50;
  }

  .overlay {
    z-index: 2001;
  }
</style>
