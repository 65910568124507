import { computed, reactive, ref } from 'vue'
import { getElementIdsReference } from '@/utils/filterListByString'
import {
  searchChoices,
  searchComponents,
  searchOptions,
  searchComponentVariant
} from '@/api'
import { PM_TYPE, SCHEDULE_TYPES, SEARCH_RESULT_TYPES } from '@/constants'

export const useElements = () => {
  const isElementsLoading = ref(false)
  const selectedForLE = ref(false)

  const elements = reactive({
    options: { elements: [], totalSize: 0 },
    components: { elements: [], totalSize: 0 },
    componentVariants: { elements: [], totalSize: 0 },
    choices: { elements: [], totalSize: 0 }
  })

  const selectedElementsIds = reactive({
    [SEARCH_RESULT_TYPES.OPTIONS]: new Map(),
    [SEARCH_RESULT_TYPES.COMPONENTS]: new Map()
  })

  const selectedElements = reactive({
    [SEARCH_RESULT_TYPES.OPTIONS]: [],
    [SEARCH_RESULT_TYPES.COMPONENTS]: []
  })

  const onHandleParentElementClick = (elementTypes, {
    item = {},
    isChosen = false,
    fetchedElements = null
  } = {}) => {
    const { item: parentType, subItem: childType } = elementTypes
    const selectedIds = selectedElementsIds[parentType] || new Map()

    if (isChosen) {
      selectedIds.delete(item.id)
      selectedElements[parentType] = selectedElements[parentType]?.filter(el => el.id !== item.id) || []
      selectedElementsIds[parentType] = selectedIds
      return
    }

    const { elements: parentElements } = fetchedElements
      ? { elements: fetchedElements[parentType] } : elements[parentType]
    const { elements: subElements } = elements[childType]
    const { elementId } = getElementIdsReference(childType)

    const getSubElementById = () => {
      const subElement = subElements.find(el => el[elementId] === item.id)
      return subElement ? item : []
    }

    const getElementById = () => parentElements.find(el => el.id === item.id)

    const selectedItem = getElementById() || getSubElementById()

    const subElementIds = selectedItem
      ? selectedItem[childType]?.map(subEl => subEl.id) || []
      : subElements.filter(el => el[elementId] === item.id).map(el => el.id)

    selectedIds.set(item.id, subElementIds)

    const updatedElements = selectedElements[parentType]?.filter(el => el.id === item.id) || []

    if (updatedElements.length) {
      selectedElements[parentType] = selectedElements[parentType]
        .map(el => (el.id === selectedItem.id ? { ...el, ...selectedItem } : el))
    }
    else {
      selectedElements[parentType] = [...(selectedElements[parentType] || []), selectedItem]
    }

    selectedElementsIds[parentType] = selectedIds
  }

  const onHandleChildElementClick = (elementTypes, { subItem = {}, isChosen = false } = {}) => {
    const elementSingularTypeName = elementTypes.item.slice(0, -1)
    const parentId = `${ elementSingularTypeName }Id`

    const parentElementIdsMap = selectedElementsIds[elementTypes.item] || new Map()
    let parentElement = selectedElements[elementTypes.item]
    const subItemIdList = parentElementIdsMap.get(subItem[parentId]) || []

    const { elementId } = getElementIdsReference(elementTypes.subItem)

    const handleChosenItem = () => {
      const targetSubItemIdx = subItemIdList.indexOf(subItem.id)

      if (targetSubItemIdx > -1) {
        if (subItemIdList.length > 1) {
          subItemIdList.splice(targetSubItemIdx, 1)
          parentElementIdsMap.set(subItem[parentId], subItemIdList)

          parentElement = parentElement.map(el => {
            const subItems = el[elementTypes.subItem].filter(subEl => subEl.id !== subItem.id)
            return { ...el, [elementTypes.subItem]: subItems }
          })
        }
        else {
          parentElementIdsMap.delete(subItem[parentId])
          parentElement = parentElement.filter(el => el.id !== subItem[parentId])
        }
        selectedElementsIds[elementTypes.item] = new Map(parentElementIdsMap)
        selectedElements[elementTypes.item] = parentElement
      }
    }

    const handleUnchosenItem = () => {
      if (parentElementIdsMap.has(subItem[parentId]) && !subItemIdList.includes(subItem.id)) {
        subItemIdList.push(subItem.id)
        parentElementIdsMap.set(subItem[parentId], subItemIdList)

        parentElement = parentElement.map(el => {
          if (el.id === subItem[elementId]) {
            return { ...el, [elementTypes.subItem]: [...el[elementTypes.subItem], subItem] }
          }
          return el
        })
        selectedElements[elementTypes.item] = parentElement
      }
      else {
        const element = {
          id: subItem[parentId],
          id2: subItem[`${ elementSingularTypeName }Id2`],
          oid: subItem[`${ elementSingularTypeName }Oid`],
          name: subItem[`${ elementSingularTypeName }Name`],
          [elementTypes.subItem]: [{ ...subItem }]
        }
        parentElementIdsMap.set(element.id, [subItem.id])
        parentElement.push(element)
      }
      selectedElementsIds[elementTypes.item] = new Map(parentElementIdsMap)
      selectedElements[elementTypes.item] = parentElement
    }

    isChosen ? handleChosenItem() : handleUnchosenItem()
  }

  const onClearCollection = elementsType => {
    selectedElements[elementsType] = []
    selectedElementsIds[elementsType] = new Map()
  }

  const totalElements = computed(() => SCHEDULE_TYPES.reduce((acc, type) => {
    acc[type] = elements[type]?.totalSize || 0
    return acc
  }, {}))

  const fetchElements = async ({ query, pmIdentifier, pmType = PM_TYPE.PRODUCT_MODEL }) => {
    isElementsLoading.value = true
    try {
      const params = { query, pmIdentifier, pmType }

      const data = await Promise.all([
        searchOptions(params),
        searchChoices(params),
        searchComponents(params),
        searchComponentVariant(params)
      ])

      SCHEDULE_TYPES.forEach((key, index) => {
        elements[key] = data[index]
      })
    }
    catch (err) {
      throw new Error('Failed to fetch the elements', err)
    }
    finally {
      isElementsLoading.value = false
    }
  }

  const selectedComponentsWithLEs = () => selectedElements[SEARCH_RESULT_TYPES.COMPONENTS]
    .map(item => ({ ...item, selectedForLE: selectedForLE.value }))

  const setSelectedForLE = status => {
    selectedForLE.value = status
  }

  return {
    elements,
    fetchElements,
    loading: isElementsLoading,
    totalElements,
    onHandleParentElementClick,
    onHandleChildElementClick,
    onClearCollection,
    selectedElementsIds,
    selectedElements,
    selectedForLE,
    setSelectedForLE,
    selectedComponentsWithLEs
  }
}
