<template>
  <div class="schedules-details-page">
    <mi-inner-loading :showing="isPageLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Loading schedule details. Please wait ... </h6>
    </mi-inner-loading>
    <div v-show="!isPageLoading" class="schedules-details-page__content">
      <schedules-details-header :schedule-name="scheduleDetails.name"></schedules-details-header>
      <div class="schedules-details-page__content">
        <schedules-details-body
          :schedule-details="scheduleDetails"
          :fetch-schedule-details="fetchScheduleDetails"
        ></schedules-details-body>
      </div>
    </div>
  </div>
</template>

<script>
  import SchedulesDetailsHeader from '@/components/schedules/details/SchedulesDetailsHeader.vue'
  import SchedulesDetailsBody from '@/components/schedules/details/SchedulesDetailsBody.vue'
  import { useSchedulesDetails } from '@/components/schedules/utils'

  export default {
    name: 'SchedulesDetailsContainer',
    components: { SchedulesDetailsBody, SchedulesDetailsHeader },
    setup() {
      const { scheduleDetails, loading: isPageLoading, fetchScheduleDetails } = useSchedulesDetails()
      return { scheduleDetails, isPageLoading, fetchScheduleDetails }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-details-page {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100%;
    background: $mi-anthracite-50;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
</style>
