<template>
  <mi-btn
    icon="wrench-bold"
    icon-size="12px"
    size="md"
    :flat="false"
    :loading="isAutoFixLoading"
    @click="onHandleAutoFixClick"
  >
    Auto Fix
  </mi-btn>
</template>

<script>
  import { ref } from 'vue'

  export default {
    name: 'AutoFixBtn',
    props: {
      collectionId: {
        type: String,
        required: true
      },
      executeAutoFix: {
        type: Function,
        required: false,
        default: () => {}
      },
      scheduleId: {
        type: String,
        required: true
      },
      handleCloseModal: {
        type: Function,
        required: false,
        default: () => {}
      },
      redirect: {
        type: Boolean,
        required: false,
        default: false
      },
      fromModal: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const isAutoFixLoading = ref(false)

      const onHandleAutoFixClick = async () => {
        isAutoFixLoading.value = true
        await props.executeAutoFix({ collectionId: props.collectionId, redirect: props.redirect })
        if (props.fromModal) {
          props.handleCloseModal()
        }
        isAutoFixLoading.value = false
      }

      return { isAutoFixLoading, onHandleAutoFixClick }
    }
  }
</script>
