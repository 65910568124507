import { isValidDate, parseDateString } from '@/utils/formatDate'
import { createRule } from './common'

export function minDate(minValue, errorMessage) {
  const isValid = value => parseDateString(value) > minValue

  return createRule({ isValid, errorMessage })
}

export function maxDate(maxValue, errorMessage) {
  const isValid = value => parseDateString(value) <= maxValue

  return createRule({ isValid, errorMessage })
}

export function validDate(errorMessage) {
  const isValid = value => isValidDate(value)
  return createRule({ isValid, errorMessage })
}
