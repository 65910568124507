<template>
  <mi-dialog
    :model-value="isModalOpen"
    :close-icon="false"
    :inner-loading="false"
    :maximized="false"
    :persistent="true"
    :custom-design-dialog="true"
    no-esc-dismiss
    no-backdrop-dismiss
  >
    <div class="modal-header-wrapper">
      <div class="modal-header-text">
        <h3 class="q-mt-none q-mb-sm modal-header">Auto fix needed</h3>
        <mi-btn
          icon="close"
          icon-type
          icon-size="18px"
          fab
          flat
          @click="handleCloseModal"
        ></mi-btn>
      </div>
      <p class="modal-text">
        This collection is not updated to the most recent version of this product model.<br />
        To edit it, first you need to Auto-fix it to ensure
        there are no errors that occur when selecting.
      </p>
      <div class="modal-actions">
        <mi-btn flat @click="handleCloseModal"> Cancel  </mi-btn>
        <auto-fix-btn
          :schedule-id="scheduleId"
          :collection-id="collectionId"
          :handle-close-modal="handleCloseModal"
          :execute-auto-fix="executeAutoFix"
          :from-modal="true"
          :redirect="true"
        ></auto-fix-btn>
      </div>
    </div>
  </mi-dialog>
</template>

<script>
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import AutoFixBtn from '@/components/schedules/details/schedule-details-setup-tab/auto-fix-btn/AutoFixBtn.vue'

  export default {
    name: 'AutofixConfirmationModal',
    components: {
      AutoFixBtn,
      MiBtn,
      MiDialog
    },
    props: {
      isModalOpen: {
        type: Boolean,
        required: true
      },
      handleCloseModal: {
        type: Function,
        required: true
      },
      scheduleId: {
        type: String,
        required: true
      },
      collectionId: {
        type: String,
        required: true
      },
      executeAutoFix: {
        type: Function,
        required: true
      }
    }
  }
</script>

<style lang="scss" >
  .modal-header {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  .modal-header-wrapper {
    display: flex;
    gap: 32px;
    flex-direction: column;
    color: $mi-anthracite-800;
    padding: 16px 24px;
  }

  .modal-text {
    font-size: 16px;
    margin: 0;
    line-height: 24px;
  }

  .modal-header-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
  }

  @media (min-width: 600px) {
    .q-dialog__inner--minimized > div {
      max-width: 628px;
    }
  }

</style>
