<template>
  <div class="mi-calendar-wrapper">
    <mi-text-field
      v-model="date"
      label="Schedule Date"
      :placeholder="mask"
      mask="##/##/####"
      :rules="rules"
      outlined
      required
    >
      <template #append>
        <mi-icon name="calendar" class="cursor-pointer">
          <q-popup-proxy ref="popUpRef" cover transition-show="scale" transition-hide="scale">
            <q-date
              v-model="date"
              minimal
              color="red"
              squared="true"
              :mask="mask"
              :navigation-min-year-month="minFirstMonth"
              :navigation-max-year-month="maxLastMonth"
            >
              <div class="row items-center justify-end">
                <mi-btn v-close-popup label="Close" color="primary" flat></mi-btn>
              </div>
            </q-date>
          </q-popup-proxy>
        </mi-icon>
      </template>
    </mi-text-field>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import MiIcon from '../MiIcon/MiIcon.vue'
  import MiTextField from '../MiTextField/MiTextField.vue'
  import MiBtn from '../MiBtn/MiBtn.vue'

  export default {
    name: 'MiCalendar',
    components: { MiIcon, MiTextField, MiBtn },
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      rules: {
        type: Array,
        default: () => []
      },
      mask: {
        type: String,
        default: 'DD/MM/YYYY'
      }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const date = ref(props.modelValue)
      const popUpRef = ref(null)

      const currentYear = new Date().getFullYear()
      const minFirstMonth = `${ currentYear }/1`
      const maxLastMonth = `${ currentYear }/12`

      watch(date, (newValue, oldValue) => {
        const updatedDateValue = newValue || oldValue
        emit('update:modelValue', updatedDateValue)
        date.value = updatedDateValue
        popUpRef.value.hide()
      })

      watch(() => props.modelValue, newValue => {
        if (newValue !== date.value) {
          date.value = newValue
        }
      })

      return {
        date,
        minFirstMonth,
        maxLastMonth,
        popUpRef
      }
    }
  }
</script>

<style scoped lang="scss">
  .mi-calendar-wrapper {
    max-width: 166px;
  }

  ::v-deep(.q-date__view) {
    min-height: 232px;
    padding: 16px 16px 0;
  }

  ::v-deep(.q-date__calendar-item button) {
    border-radius: 0;
  }

  ::v-deep(.bg-red) {
    /* stylelint-disable declaration-no-important */
    background: $mi-red-500 !important;
  }

  ::v-deep(.q-date__navigation > div:last-child) {
    display: none;
  }

  ::v-deep(.q-date__navigation > div:nth-last-child(3)) {
    display: none;
  }

  ::v-deep(.q-date__navigation > div:nth-last-child(2)) {
    pointer-events: none;
  }
</style>
