<template>
  <div class="schedule-table-empty-state schedule-table-empty-state-bom">
    <div class="schedule-table-empty-state__wrapper">
      <div class="schedule-table-empty-state__wrapper--content">
        <div class="flex items-center content-wrapper">
          <create-collection-btn :is-flat="true"></create-collection-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CreateCollectionBtn
    from '@/components/schedules/details/schedule-details-setup-tab/create-collection-btn/CreateCollectionBtn.vue'

  export default {
    name: 'ScheduleSetupTableEmptyState',
    components: { CreateCollectionBtn }
  }
</script>

<style lang="scss">
  .schedule-table-empty-state {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50%;
    z-index: 2;

    &__wrapper {
      width: 100%;
      background-color: $mi-white;

      &--content {
        padding: 1.5rem;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }
  }

  .schedule-table-empty-state-bom:hover {
    cursor: pointer;
  }

  .content-wrapper {
    gap: 9px;
  }
</style>
